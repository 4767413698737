export const Routes = {
	APP: '/',
	ADMIN: '/admin',
	LOGIN: 'login',
	FORGOT_PASSWORD: '/forgot-password',
	LOGS: '/admin/logs',
	USERS_MANAGER: '/admin/users-management',
	MEMBERS: '/admin/members',
	SUPPPORT_INQUIRIES: '/admin/support-inquiries',
	SETTINGS: '/admin/settings',
	STATISTICS: '/admin/statistics',
	GOLF_CLUB_MANAGEMENT: '/admin/clubs',
	LOCATIONS: '/admin/locations',
	FAQS: '/admin/faqs',
	ORDERS: '/admin/orders',
	OFFERS: '/admin/offers'
};
